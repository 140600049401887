@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/elevation';
@use '@sats-group/ui-lib/tokens/light';


.session-block {
  $breakpointMedium: 500px;
  $breakpoint: 600px;
  $block: &;

  @mixin interaction {
    &:not(&--no-interaction) {
      cursor: pointer;
      @media (hover: hover) {
        &:hover {
          @content
        }
      }
    }
  }

  background-color: light.$signal-surface-neutral;
  border: 0;
  padding: 0;
  display: flex;
  text-align: left;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid light.$ge-divider-default;

  &--white {
    background-color: light.$surface-primary-default;
    color: light.$on-surface-primary-alternate;
    @include interaction {
      background-color: light.$surface-primary-hover;
    }
  }

  &--light-gray {
    background-color: light.$surface-secondary-default;
    color: light.$on-surface-primary-alternate;
    @include interaction {
      background-color: light.$surface-primary-hover;
    }
  }

  &--orange {
    background-color: light.$workout-surface-gymfloor;
    color: light.$on-workout-surface-gymfloor-alternate;
    @include interaction {
      background-color: light.$workout-surface-gymfloor-hover;
    }
  }

  &--green {
    background-color: light.$workout-surface-other;
    color: light.$on-workout-surface-other-alternate;
    @include interaction {
      background-color: light.$workout-surface-other-hover;
    }
  }

  &--red {
    background-color: light.$workout-surface-gx;
    color: light.$on-workout-surface-gx-alternate;
    @include interaction {
      background-color: light.$workout-surface-gx-hover;
    }
  }

  &--gray {
    background-color: light.$signal-surface-neutral;
    color: light.$on-signal-surface-neutral-alternate;
    @include interaction {
      background-color: light.$surface-secondary-default;
    }
  }

  &--blue {
    background-color: light.$workout-surface-pt;
    color: light.$on-workout-surface-pt-alternate;
    @include interaction {
      background-color: light.$workout-surface-pt-hover;
    }
  }

  &--purple {
    background-color: light.$workout-surface-bootcamp;
    color: light.$on-workout-surface-bootcamp-alternate;
    @include interaction {
      background-color: light.$workout-surface-bootcamp-hover;
    }
  }

  &--clicked {
    background-color: light.$surface-primary-selected;
    color: light.$on-surface-primary-default;

    @include interaction {
      background-color: light.$surface-primary-hover;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__status-wrapper {
    display: flex;
    align-items: center;
    gap: spacing.$xxs;
  }

  &__status {
    background-color: inherit;
    height: 100%;
    width: 4px;
    flex-shrink: 0;

    &--orange {
      background-color: light.$workout-surface-gymfloor;
      border-right: 1px solid light.$ge-workouts-gym-floor;
      border-left: 1px solid light.$ge-workouts-gym-floor;
    }

    &--green {
      background-color: light.$ge-workouts-other;
    }

    &--red {
      background-color: light.$ge-workouts-gx;
    }

    &--gray {
      background: repeating-linear-gradient(
        -45deg,
        light.$ge-indicator-tags-neutral-default,
        light.$ge-indicator-tags-neutral-default 4px,
        light.$signal-surface-neutral 4px,
        light.$signal-surface-neutral 8px
      );
    }

    &--blue {
      background-color: light.$ge-workouts-pt;
    }

    &--purple {
      background-color: light.$ge-workouts-bootcamp;
    }

    &--dark-green {
      background-color: light.$ge-indicators-positive-default;
    }
  }

  &__text {
    padding: spacing.$xxs;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &--center-text {
      display: flex;
      align-items: center;
    }
  }

  &__description-text {
    &--completed {
      color: light.$on-surface-success;
    }
  }

  &__text-inner {
    width: 80%;
  }

  &__session-icons {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__completed-icon {
    display: flex;
    color: light.$on-surface-success;
  }

  &__title-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
